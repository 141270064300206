import { Link } from "gatsby"
import React, { Component } from "react"
import Image from "../components/image"
import BurgerButton from "./burger-button"

export default class Navbar extends Component {
  state = {
    isOpen: false,
    active: false,
  
  }
  navigationToggler = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      active: !this.state.active,
    })
  }
  componentDidMount(){
    window.addEventListener('scroll', () => {
       let activeClass = 'white-text';
       if(window.scrollY > 0){
           activeClass = 'black-text white';
       }
       else if(window.scrollY == 0){
          activeClass =""
       }

       this.setState({ activeClass });
    });
}
classBoxHandler =()=>{
  if(this.state.isOpen){
    return "navigation-box navigation-open"
  }
  else if(!this.state.isOpen){
   
    return `navigation-box  navigation-close ${this.state.activeClass} `
  }
}
classNavHandler =()=>{
  if(this.state.isOpen  ){
    return "navigation navigation-open "
  }
  else if(!this.state.isOpen ){
    return `navigation  navigation-close ${this.state.activeClass}`
  }

}


  render() {
    return (
      <>
        <div 
          className={
            // this.state.isOpen
            //   ? "navigation-box  navigation-open"
            //   : "navigation-box  navigation-close" 
             this.classBoxHandler()
          }
        >
          <nav
            className={
              // this.state.isOpen
              //   ? "navigation navigation-open"
              //   : "navigation navigation-close"
              this.classNavHandler()
            }
          >
            <Link to="/">
              <div className="navigation__logo">
                <Image />
              </div>
            </Link>

            <div className="navigation__links d-flex flex-column justify-content-around ">
             

              <ul className="navigation__list d-flex flex-column flex-lg-row">
                <Link
                  className="col p-0 text-center d-flex flex-column justify-content-center"
                  to="/o-nas/"
                >
                  <li className={this.state.activeClass ? "black-text": "white-text"}>O NAS</li>
                </Link>
                {/* <Link
                  className=" col p-0 text-center d-flex flex-column justify-content-center"
                  to="/wspolpraca/"
                >
                  <li>WSPÓŁPRACA</li>
                </Link>
                <Link
                  className=" col p-0 text-center d-flex flex-column justify-content-center"
                  to="/promocje/"
                >
                  <li>PROMOCJE</li>
                </Link> */}
                <Link
                  className="col p-0 text-center d-flex flex-column justify-content-center"
                  to="/kontakt/"
                >
                  <li className={this.state.activeClass ? "black-text": "white-text"}>KONTAKT</li>
                </Link>
                <Link
                  className="navigation__menu-link col p-0 text-center d-flex flex-column justify-content-center"
                  to="/menu/"
                >
                  <li className={this.state.activeClass ? "black-text": "white-text"}>MENU</li>
                </Link>
              </ul>
            </div>
          </nav>
        </div>
        <div className="nav-top">
          <div
            id="menu-toggle"
            onClick={this.navigationToggler}
            className={
              this.state.isOpen
                ? "navigation-burger open"
                : "navigation-burger "
            }
          >
            <div id="hamburger">
              <span className="hamburger-span"></span>
              <span className="hamburger-span"></span>
              <span className="hamburger-span"></span>
            </div>
            <div id="cross">
              <span className="hamburger-span"></span>
              <span className="hamburger-span"></span>
            </div>
          </div>
        </div>
      </>
    )
  }
}
